
import api from "@/api";
import { Options, Vue } from "vue-class-component";
import Header from "@/components/content/Header.vue";
import Paragraph from "@/components/content/Paragraph.vue";
import Codeblock from "@/components/content/Codeblock.vue";
import CodeblockExec from "@/components/content/CodeblockExec.vue";
import Image from "@/components/content/Image.vue";

@Options({
  components: {
    Header,
    Paragraph,
    Codeblock,
    CodeblockExec,
    Image,
  },
  data(): {
    guide: any;
    content: any;
    mapTypeComponents: any;
  } {
    const mapTypeComponents = {
      header: "Header",
      paragraph: "Paragraph",
      codeblock: "Codeblock",
      "codeblock-exec": "CodeblockExec",
      "image-path": "Image",
    };
    return {
      guide: {} as any,
      content: {} as any,
      mapTypeComponents,
    };
  },
  async beforeMount() {
    var resp = await api.getLessonPreview(this.$route.params.encoded);
    console.log(resp);
    // this.guide = await api.getGuide(this.$route.params.id);
    this.content = resp["lesson"]["content_blocks"];
    console.log(this.content);
    for (var i = 0; i < this.content.length; i++) {
      console.log(this.content[i].type);
    }
  },
})
export default class PreviewLesson extends Vue {}
