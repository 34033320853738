import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0df536ef"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "cardbody" }
const _hoisted_4 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_course_card_button = _resolveComponent("course-card-button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: "cardrouterlink",
    to: _ctx.to
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: _ctx.img,
          alt: ""
        }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h4", null, _toDisplayString(_ctx.title), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.stack), 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", null, _toDisplayString(_ctx.info), 1),
            _createVNode(_component_course_card_button, { text: _ctx.button_text }, null, 8, ["text"])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["to"]))
}