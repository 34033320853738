
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    value: String,
    formatting: String,
  },
})
export default class Header extends Vue {}
