
import { Options, Vue } from "vue-class-component";
import api from "../api/index";
import { Step } from "../api/types";
@Options({
  emits: ["showLoginPopup"],
  props: {
    step: {} as Step,
  },
  beforeUpdate() {
    this.updateClass();
  },
  methods: {
    updateClass: function () {
      if (this.step.completed) {
        this.stepCompletedClass = "step-body-completed";
        this.stepCompletedTitleClass = "step-title-completed";
      } else {
        this.stepCompletedClass = "";
        this.stepCompletedTitleClass = "";
      }
    },
    check: async function (e: HTMLInputElement) {
      if (this.$cookies.get("token") == null) {
        this.$emit("showLoginPopup");
        return;
      }
      this.step.completed = !this.step.completed;
      this.updateClass();
      const path_id = this.$route.params.id;
      const step_id = this.step.id;
      const res = await api.updatePathStep(
        this.step.completed,
        path_id,
        step_id
      );
    },
    expandDetailedView: function (e: Event) {
      if (e.target instanceof Element) {
        if ((e.target as Element).className === "check") {
          return;
        }
      }
      this.expanded = !this.expanded;
      if (this.expanded) {
        this.detailedExpandedClass = "detailed-explained";
      } else {
        this.detailedExpandedClass = "detailed-hidden";
      }
    },
  },
  computed: {
    resourcesNotEmpty() {
      if (
        this.step.topic.resources == null ||
        this.step.topic.resources.length == 0
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      stepCompletedClass: "",
      stepCompletedTitleClass: "",
      levelColorClasses: {
        easy: "level-easy",
        medium: "level-medium",
        hard: "level-hard",
      },
      expanded: false,
      detailedExpandedClass: "detailed-hidden",
      imgs: {
        video: require("../assets/video.svg"),
        blogpost: require("../assets/blog.svg"),
        bookchapter: require("../assets/book.svg"),
      },
    };
  },
})
export default class PathStep extends Vue {}
