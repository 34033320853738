
import api from "@/api";
import { Options, Vue } from "vue-class-component";
import { marked } from "marked";
@Options({
  computed: {
    markdown() {
      return marked.parse(this.value);
    },
  },
  props: {
    value: String,
  },
  data(): {
    tooltip: string;
    id: string;
  } {
    return {
      tooltip: "None",
      id: "tooltip-" + Math.random().toString(36).substring(2, 9),
    };
  },
  methods: {
    async explainTerm() {
      console.log("Mouse up");
      this.tooltip = "None";
      const selection = window.getSelection();
      if (selection) {
        const selectedText = selection.toString();
        if (selectedText && selectedText.length > 3) {
          console.log("Explaining term");
          this.tooltip = "Loading...";
          //wait for tooltip to be rendered
          await this.$nextTick();
          var rect = selection.getRangeAt(0).getBoundingClientRect();
          const tooltipLeft = Math.max(rect.left - rect.width, 0);
          const tooltipTop = Math.max(rect.top, 0);
          const tooltip = document.getElementById(this.id);
          tooltip?.style.setProperty("left", `${tooltipLeft}px`);
          tooltip?.style.setProperty("top", `${tooltipTop + 20}px`);
          const suggestion = await api.getExplanation(this.value, selectedText);
          console.log(suggestion);
          this.tooltip = suggestion.message;
        }
      }
    },
  },
})
export default class Paragraph extends Vue {}
