// Class for manipulating the API

import { Path } from "./types";
import { eraseCookie } from "./utils";

class Api {
  url: string;
  headers: HeadersInit;
  authorization: HeadersInit;

  constructor(url: string, headers: HeadersInit) {
    this.url = url;
    this.headers = headers;
    this.authorization = { TOKEN: getCookie("token") };
  }

  async getGuide(id: string): Promise<any> {
    const response = await fetch(this.url + "/pages/" + id, {
      method: "GET",
      headers: {
        ...this.headers,
        ...this.authorization,
      },
    });
    const guide: any = await response.json();
    return guide;
  }

  async getGuideList(): Promise<any> {
    const response = await fetch(this.url + "/pages?page=1&window=20", {
      method: "GET",
      headers: {
        ...this.headers,
        ...this.authorization,
      },
    });
    const guides: any = await response.json();
    return guides.pages;
  }

  async getGuideContent(url: string): Promise<any> {
    const response = await fetch(url, {
      method: "GET",
    });
    const guide: any = await response.json();
    return guide;
  }

  async launchCodeRun(code: string): Promise<any> {
    const response = await fetch(this.url + "/coderuns", {
      method: "POST",
      headers: {
        ...this.headers,
        ...this.authorization,
      },
      body: JSON.stringify({ snippet: code }),
    });
    const run: any = await response.json();
    return run;
  }

  async getPath(id: string): Promise<Path> {
    const response = await fetch(this.url + "/paths/" + id, {
      method: "GET",
      headers: {
        ...this.headers,
        ...this.authorization,
      },
    });
    const path: Path = await response.json();
    return path;
  }

  async getStartedPaths(): Promise<Array<string>> {
    const response = await fetch(this.url + "/path-progress", {
      method: "GET",
      headers: {
        ...this.headers,
        ...this.authorization,
      },
    });
    const paths: Array<string> = await response.json();
    return paths;
  }

  async updatePathStep(completed: boolean, path_id: string, step_id: string) {
    const response = await fetch(this.url + "/path-progress", {
      method: "POST",
      headers: {
        ...this.headers,
        ...this.authorization,
      },
      body: JSON.stringify({ path_id, step_id, completed }),
    });
    return response.json();
  }

  async getCompletedSteps(path_id: string): Promise<any> {
    const responseJson = await this.processRequest(
      this.url + "/path-progress/" + path_id,
      "GET",
      null
    );
    return responseJson;
  }

  async getLessonPreview(bodyBase64: string): Promise<any> {
    const jsonBody = JSON.parse(atob(bodyBase64));
    const responseJson = await this.processRequest(
      this.url + "/lessons/preview",
      "POST",
      JSON.stringify(jsonBody)
    );
    return responseJson;
  }

  async getExplanation(context: string, question: string): Promise<any> {
    const responseJson = await this.processRequest(
      this.url + "/ai",
      "POST",
      JSON.stringify({ context, question })
    );
    return responseJson;
  }

  async getUserInfo(): Promise<any> {
    const responseJson = await this.processRequest(
      this.url + "/users",
      "GET",
      null
    );
    return responseJson;
  }

  async processRequest(url: string, method: string, body: any): Promise<any> {
    const response = await fetch(url, {
      method: method,
      headers: {
        ...this.headers,
        ...this.authorization,
      },
      body: body,
    });
    if (response.status == 200 || response.status == 201) {
      return response.json();
    }
    if (response.status == 401) {
      console.log(response.json());
      eraseCookie("token");
      throw Error("unauthenticated");
    } else {
      console.log("Unknown error during API request");
      console.log(response.json());
      throw Error("Failed request to API");
    }
  }

  getGoogleLoginLink() {
    return this.url + "/auth/google/login";
  }
}

let api_url = "https://api.amazemind.io/api";
if (process.env.NODE_ENV == "dev") {
  api_url = "http://localhost:4040/api";
}
export default new Api(api_url + "/v1", {
  "Content-Type": "application/json",
});

function getCookie(name: string): string {
  const nameLenPlus = name.length + 1;
  return document.cookie
    .split(";")
    .map((c) => c.trim())
    .filter((cookie) => {
      return cookie.substring(0, nameLenPlus) === `${name}=`;
    })
    .map((cookie) => {
      return decodeURIComponent(cookie.substring(nameLenPlus));
    })[0];
}
