import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "about-block" }
const _hoisted_2 = { class: "grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GuideCard = _resolveComponent("GuideCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.guides, (guide) => {
        return (_openBlock(), _createBlock(_component_GuideCard, {
          key: guide.id,
          Title: guide.title,
          ID: guide.id
        }, null, 8, ["Title", "ID"]))
      }), 128))
    ])
  ]))
}