import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-backdrop" }
const _hoisted_2 = {
  class: "modal",
  role: "dialog",
  "aria-labelledby": "modalTitle",
  "aria-describedby": "modalDescription"
}
const _hoisted_3 = {
  class: "modal-header",
  id: "modalTitle"
}
const _hoisted_4 = {
  class: "modal-body",
  id: "modalDescription"
}
const _hoisted_5 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "modal-fade" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("header", _hoisted_3, [
            _renderSlot(_ctx.$slots, "header", {}, () => [
              _createTextVNode(" Login to continue ")
            ]),
            _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
              "aria-label": "Close modal"
            }, " x ")
          ]),
          _createElementVNode("section", _hoisted_4, [
            _renderSlot(_ctx.$slots, "body", {}, () => [
              _createTextVNode(" To save your progress, create a free account ")
            ])
          ]),
          _createElementVNode("footer", _hoisted_5, [
            _createElementVNode("button", {
              type: "button",
              class: "btn-green",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args)))
            }, "LOGIN")
          ])
        ])
      ])
    ]),
    _: 3
  }))
}