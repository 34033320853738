
import { Options, Vue } from "vue-class-component";
import { eraseCookie } from "../api/utils";

@Options({
  async mounted() {
    let router = this.$router;
    if (this.$cookies.get("token") === null) {
      setTimeout(function () {
        router.push({ name: "Landing" });
      }, 1500);
    } else {
      eraseCookie("token");
      // location.reload();
    }
  },
})
export default class Logout extends Vue {}
