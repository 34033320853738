
import { Options, Vue } from "vue-class-component";
import SideBar from "@/components/SideBar.vue";
import NavBar from "@/components/NavBar.vue";
import Container from "@/components/Container.vue";

@Options({
  components: {
    SideBar,
    Container,
    NavBar,
  },
})
export default class Home extends Vue {}
