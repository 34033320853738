
import { Options, Vue } from "vue-class-component";
import api from "../api/index";
import SearchBar from "@/components/SearchBar.vue";
import CourseCard from "@/components/CourseCard.vue";
@Options({
  components: { CourseCard, SearchBar },
  data() {
    return {
      courses: [
        {
          id: "python",
          title: "Python Backend Developer",
          stack: "Python, Django, PostgreSQL, Algorithms",
          info: "25 Chapters",
          button_text: "Start",
          img: require("../assets/sample_course.png"),
        },
        {
          id: "ux",
          title: "UX Designer",
          stack: "UI, UX, Figma, Adobe",
          info: "13 Chapters",
          button_text: "Start",
          img: require("../assets/ux-interface.svg"),
        },
        {
          id: "frontend",
          title: "Frontend Developer",
          stack: "HTML5, CSS, VueJS, React, Angular",
          info: "35 Chapters",
          button_text: "Start",
          img: require("../assets/ux.svg"),
        },
        {
          id: "testing",
          title: "Quality Assurance Engineer",
          stack: "Python, Golang, Network, Software Development",
          info: "20 Chapters",
          button_text: "Start",
          img: require("../assets/test.svg"),
        },
      ],
      started_courses: [],
    };
  },
  methods: {
    generateLink(id: string): string {
      return "paths/" + id;
    },
  },
  async beforeMount() {
    let started_paths = await api.getStartedPaths();
    for (let i = 0; i < this.courses.length; i++) {
      if (started_paths.includes(this.courses[i].id)) {
        this.started_courses.push(this.courses[i]);
      }
    }
  },
})
export default class Paths extends Vue {}
