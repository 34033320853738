
import { Options, Vue } from "vue-class-component";
import PathStep from "../components/PathStep.vue";

@Options({
  components: { PathStep },
  emits: ["showLogin"],
  props: {
    chapter: Object,
  },
  methods: {
    toggleLogin() {
      this.$emit("showLogin");
    },
  },
})
export default class PathChapterHolder extends Vue {}
