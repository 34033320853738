
import { Options, Vue } from "vue-class-component";
import api from "../api/index";
import { Path, Chapter, Step } from "../api/types";
import PathChapterHolder from "@/components/PathChapterHolder.vue";
import ModalPopup from "@/components/Modal.vue";

@Options({
  components: { PathChapterHolder, ModalPopup },
  data(): {
    chapters: Chapter[];
    path: Path;
    isModalVisible: boolean;
  } {
    return {
      path: {} as Path,
      chapters: [] as Chapter[],
      isModalVisible: false,
    };
  },
  methods: {
    togglePopup() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      location.reload();
    },
  },
  async beforeMount() {
    this.path = await api.getPath(this.$route.params.id);
    this.chapters = this.path.chapters;

    try {
      const completedSteps = await api.getCompletedSteps(this.$route.params.id);
      this.path.chapters.forEach(function (chapter: Chapter) {
        chapter.steps.forEach(function (step: Step) {
          completedSteps.completed_steps.forEach(function (step_id: string) {
            if (step_id === step.id) {
              step.completed = true;
            }
          });
        });
      });
    } catch (error) {
      console.log("caught error " + error);
    }
  },
  props: {
    id: String,
  },
})
export default class SinglePath extends Vue {}
