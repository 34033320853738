
import api from "@/api";
import { Options, Vue } from "vue-class-component";
import Editor from "@/components/content/Editor.vue";

@Options({
  computed: {
    outputVisible() {
      if (this.Output != "") {
        return "visible";
      } else {
        return "hidden";
      }
    },
    loadingVisible() {
      if (this.Loading) {
        return "visible";
      } else {
        return "hidden";
      }
    },
  },
  methods: {
    onEdited(value: string) {
      this.editedvalue = value;
    },
    async runCode() {
      this.Output = "";
      this.fullOutputVisible = "visible";
      this.Loading = true;

      const logs = await api.launchCodeRun(this.editedvalue);
      this.Loading = false;
      console.log(logs);
      this.Output = logs.logs;
    },
  },
  data(): {
    Output: string;
    Loading: boolean;
    fullOutputVisible: string;
    editedvalue: string;
  } {
    return {
      Output: "",
      Loading: false,
      fullOutputVisible: "hidden",
      editedvalue: this.value,
    };
  },
  components: {
    Editor,
  },
  props: {
    value: String,
    language: String,
  },
})
export default class CodeblockExec extends Vue {}
