
import api from "@/api";
import { Options, Vue } from "vue-class-component";
import Header from "@/components/content/Header.vue";
import Paragraph from "@/components/content/Paragraph.vue";
import Codeblock from "@/components/content/Codeblock.vue";
import CodeblockExec from "@/components/content/CodeblockExec.vue";
import Image from "@/components/content/Image.vue";

@Options({
  components: {
    Header,
    Paragraph,
    Codeblock,
    CodeblockExec,
    Image,
  },
  data(): {
    guide: any;
    content: any;
    mapTypeComponents: any;
  } {
    const mapTypeComponents = {
      header: "Header",
      paragraph: "Paragraph",
      codeblock: "Codeblock",
      "codeblock-exec": "CodeblockExec",
      "image-path": "Image",
    };
    return {
      guide: {} as any,
      content: {} as any,
      mapTypeComponents,
    };
  },
  async beforeMount() {
    this.guide = await api.getGuide(this.$route.params.id);
    this.content = await api.getGuideContent(this.guide.signed_url);
  },
})
export default class Guide extends Vue {}
