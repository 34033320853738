
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    Title: String,
    ID: Number,
  },
})
export default class GuideCard extends Vue {}
