
import { Options, Vue } from "vue-class-component";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";

@Options({
  components: {},
  data(): {
    EditorID: string;
  } {
    return {
      EditorID: "editor-" + Math.random().toString(12),
    };
  },
  props: {
    value: String,
    Language: String,
    lineNumbers: {
      type: String,
      default: "on",
    },
  },
  async mounted() {
    // const editorOptions = ;
    const container = document.getElementById(this.EditorID)!;
    const editor = monaco.editor.create(container, {
      value: this.value,
      language: this.Language,
      minimap: { enabled: false },
      theme: "vs-dark",
      lineNumbers: this.lineNumbers,
      scrollBeyondLastLine: false,
      wrappingStrategy: "advanced",
      overviewRulerLanes: 0,
      fontSize: 14,
    });

    const height = Math.min(450, editor.getContentHeight());
    container.style.height = `${height}px`;
    editor.layout();
    window.addEventListener("resize", (func) => {
      editor.layout();
    });
    editor.onDidChangeModelContent((e) => {
      console.log("Sending event");
      this.$emit("edited", editor.getValue());
    });
  },
})
export default class Editor extends Vue {}
