<template>
  <router-link
    active-class="font-bold text-gray-800 bg-gray-100"
    :to="to"
    class="m-2 flex flex-col rounded-md p-1 text-gray-600 hover:cursor-pointer hover:bg-gray-100 hover:text-gray-800 hover:drop-shadow-md"
  >
    <div class="flex w-full justify-center">
      <img class="flex w-8" :src="icon" alt="" />
    </div>
    <div class="mt-2 flex w-full justify-center text-sm">
      {{ name }}
    </div>
  </router-link>
</template>

<script>
export default {
  name: "SideBarItem",
  props: {
    to: String,
    name: String,
    icon: String,
  },
};
</script>
