
import { Options, Vue } from "vue-class-component";
import Editor from "@/components/content/Editor.vue";

@Options({
  components: {
    Editor,
  },
  props: {
    value: String,
    language: String,
  },
})
export default class Codeblock extends Vue {}
