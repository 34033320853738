
import { Options, Vue } from "vue-class-component";

@Options({
  methods: {
    close() {
      this.$emit("close");
    },
    login() {
      this.$router.push({
        name: "Login",
      });
    },
  },
})
export default class ModalPopup extends Vue {}
