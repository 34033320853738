
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  methods: {},
  props: {
    order: Number,
    id: String,
  },
})
export default class CourseEditorItemSection extends Vue {}
