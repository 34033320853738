
import { Options, Vue } from "vue-class-component";
import CourseCardButton from "../components/CourseCardButton.vue";

@Options({
  components: { CourseCardButton },
  methods: {
    login() {
      if (this.$cookies.get("token") == null) {
        this.$router.push({
          name: "Login",
        });
      } else {
        this.$router.push({
          name: "Paths",
        });
      }
    },
  },
  data() {
    return {
      landing_image: require("../assets/online-class.svg"),
    };
  },
})
export default class Landing extends Vue {}
