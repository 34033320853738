
import { Options, Vue } from "vue-class-component";
import InputFieldText from "../components/InputFieldText.vue";
import InputFieldTextArea from "../components/InputFieldTextArea.vue";
import CourseEditorItemSection from "../components/CourseEditorItemSection.vue";
import CourseEditorButton from "../components/CourseEditorButton.vue";

@Options({
  components: {
    InputFieldText,
    InputFieldTextArea,
    CourseEditorItemSection,
    CourseEditorButton,
  },
  props: {
    createNewCourse: Boolean,
  },
  data() {
    return {
      course_data: {},
      title: "",
    };
  },
  methods: {
    async addSection(order: number) {
      await this.createSection(order);
    },
    async createCourse() {
      const res = await fetch(`api/v1/courses`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ lang: "ru", title: "" }),
      });
      const data = await res.json();
      this.course_id = data.id;
    },
    async fetchCourse() {
      const res = await fetch(`api/v1/courses/${this.course_id}`);
      this.course_data = await res.json();
      let new_arr: any[] = [];
      this.course_data.sections_ordering.forEach((section_id: string) => {
        new_arr.push(
          this.course_data.sections.filter(
            (item: any) => item.id === section_id
          )[0]
        );
      });
      this.course_data.sections = new_arr;
    },
    async saveCourse() {
      let { created, updated, schema_version, ...course_to_save } =
        this.course_data;
      course_to_save.title = this.title;
      console.log(course_to_save);
      console.log(this.title);

      await fetch(`api/v1/courses/${this.course_id}`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify(course_to_save),
      });
    },
    async createSection(order: number) {
      const section_object = {
        item: {
          name: "",
          type: "lessons",
        },
        order: order,
      };
      await fetch(`api/v1/courses/${this.course_id}/sections`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(section_object),
      });
      await this.fetchCourse();
    },
  },
  async created() {
    if (this.createNewCourse) {
      await this.createCourse();
      await this.createSection(0);
      await this.fetchCourse();
    }
  },
})
export default class CourseEditor extends Vue {}
