import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Account from "../views/Account.vue";
import CourseEditor from "../views/CourseEditor.vue";
import Login from "../views/Login.vue";
import Paths from "../views/Paths.vue";
import Path from "../views/SinglePath.vue";
import Logout from "../views/Logout.vue";
import Landing from "../views/Landing.vue";
import Guide from "../views/Guide.vue";
import GuidesList from "../views/GuidesList.vue";
import PreviewLesson from "../views/PreviewLesson.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/account",
    name: "Account",
    component: Account,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/paths",
    name: "Paths",
    component: Paths,
  },
  {
    path: "/paths/:id",
    name: "Path",
    component: Path,
    props: true,
  },
  {
    path: "/guides",
    name: "Guides",
    component: GuidesList,
  },
  {
    path: "/guides/:id",
    name: "Guide",
    component: Guide,
  },
  {
    path: "/courses",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/new",
    name: "CourseEditor",
    component: CourseEditor,
    props: { createNewCourse: true },
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "/",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/preview/:encoded",
    name: "Preview Lesson",
    component: PreviewLesson,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
