import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-686c810e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-container" }
const _hoisted_2 = { class: "input-header" }
const _hoisted_3 = ["placeholder", "maxlength"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.name), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.notes), 1)
    ]),
    _createElementVNode("input", {
      type: "text",
      placeholder: _ctx.prompt,
      maxlength: _ctx.maxlength,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
    }, null, 40, _hoisted_3)
  ]))
}