import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex h-screen w-full flex-nowrap font-sans text-lg" }
const _hoisted_2 = { class: "flex h-full w-full flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_side_bar = _resolveComponent("side-bar")!
  const _component_nav_bar = _resolveComponent("nav-bar")!
  const _component_container = _resolveComponent("container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_side_bar, { class: "static hidden w-20 flex-col place-content-center justify-between border-r-2 border-solid sm:relative sm:flex" }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_nav_bar),
      _createVNode(_component_container)
    ])
  ]))
}