import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "flex h-16" }
const _hoisted_3 = {
  href: "/",
  class: "flex h-full w-full"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_side_bar_item = _resolveComponent("side-bar-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("a", _hoisted_3, [
          _createElementVNode("img", {
            class: "m-auto flex w-10",
            src: _ctx.logo
          }, null, 8, _hoisted_4)
        ])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (item) => {
        return (_openBlock(), _createBlock(_component_side_bar_item, {
          key: item.to,
          to: item.to,
          name: item.name,
          icon: item.icon
        }, null, 8, ["to", "name", "icon"]))
      }), 128))
    ]),
    (_ctx.token)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bottom_menu, (item) => {
            return (_openBlock(), _createBlock(_component_side_bar_item, {
              key: item.to,
              to: item.to,
              name: item.name,
              icon: item.icon
            }, null, 8, ["to", "name", "icon"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}