
import api from "@/api/index";
import { Options, Vue } from "vue-class-component";
import GuideCard from "@/components/GuideCard.vue";

@Options({
  components: {
    GuideCard,
  },
  data(): {
    guides: any[];
  } {
    return {
      guides: [],
    };
  },
  async mounted() {
    this.guides = await api.getGuideList();
    console.log(this.guides);
  },
})
export default class GuidesList extends Vue {}
