function eraseCookie(name: string) {
  document.cookie =
    name +
    "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=.amazemind.io;";
  document.cookie =
    name +
    "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=localhost;";
}

export { eraseCookie };
