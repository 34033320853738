
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  methods: {},
  props: {
    name: String,
    notes: String,
    prompt: String,
    maxlength: {
      type: String,
      default: 50,
    },
  },
})
export default class InputFieldTextArea extends Vue {}
