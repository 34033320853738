
import { Options, Vue } from "vue-class-component";
import CourseCardButton from "../components/CourseCardButton.vue";

@Options({
  components: { CourseCardButton },
  props: {
    title: String,
    info: String,
    to: String,
    stack: String,
    button_text: String,
    img: String,
  },
})
export default class CourseCard extends Vue {}
