
import { Options, Vue } from "vue-class-component";
import SideBarItem from "../components/SideBarItem.vue";

@Options({
  components: { SideBarItem },
  computed: {
    token() {
      if (this.$cookies.get("token") === null) {
        return false;
      }
      return true;
    },
  },
  data() {
    var first_item = {
      to: "/account",
      name: "Account ",
      icon: require("../assets/icon_user_default.svg"),
    };
    if (this.$cookies.get("token") === null) {
      first_item = {
        to: "/login",
        name: "Login",
        icon: require("../assets/icon_user_default.svg"),
      };
    }
    return {
      logo: require("../assets/brain.svg"),
      selected: "",
      menu: [
        first_item,
        // {
        //   to: "/paths",
        //   name: "Paths",
        //   icon: require("../assets/destination.svg"),
        // },
        {
          to: "/guides",
          name: "Guides",
          icon: require("../assets/cube.svg"),
        },
        // {
        //   to: "/courses",
        //   name: "Courses",
        //   icon: require("../assets/icon_courses.svg"),
        // },
      ],
      bottom_menu: [
        {
          to: "/logout",
          name: "Logout",
          icon: require("../assets/logout.svg"),
        },
      ],
    };
  },
})
export default class SideBar extends Vue {}
