
import { Options, Vue } from "vue-class-component";
import CourseEditorButton from "../components/CourseEditorButton.vue";
import api from "../api/index";
@Options({
  components: { CourseEditorButton },
  methods: {
    signin: function (e: Event) {
      window.location.href = this.g_link;
    },
  },
  data() {
    return {
      email: "",
      g_icon: require("../assets/google.svg"),
      g_link: api.getGoogleLoginLink(),
    };
  },
})
export default class SignUp extends Vue {}
