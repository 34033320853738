
import { Options, Vue } from "vue-class-component";

@Options({
  computed: {
    imgURL() {
      return "https://storage.googleapis.com/" + this.Value;
    },
  },
  props: {
    Value: String,
    Formatting: String,
  },
})
export default class Image extends Vue {}
