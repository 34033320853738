
import { Options, Vue } from "vue-class-component";
import api from "../api/index";

@Options({
  data() {
    return {
      email: "",
    };
  },
  async beforeCreate() {
    const user = await api.getUserInfo();
    this.email = user.email;
  },
})
export default class Account extends Vue {}
